<template>
    <b-row>
        <LoaderComponent v-if="isLoading"/>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form  @submit="saveNewArticle">
                    <b-col cols="12" class="mt-16">
                        <b-row>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="รหัสบทความ:" label-for="input-2" :invalid-feedback="articleCode">
                                    <b-form-input v-model="articleCode" id="input-2" placeholder="กรอกรหัสบทความ"
                                        required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="ประเภทบทความ:" label-for="input-2">
                                    <b-form-select v-model="articleTypeSelected" :options="articleType" required></b-form-select>
                                </b-form-group>

                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="เผยแพร่เพื่อ:" label-for="input-2">
                                    <b-form-select v-model="publishTypeSelected" :options="publishType" required> </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="วันที่รับ:" label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`"
                                        v-model="receivedDate" required></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>

                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row class="mb-32">
                            <b-col cols="6">
                                <b-form-group id="input-group-2" label="ชื่อบทความภาษาไทย:" label-for="input-2">
                                    <b-form-input v-model="articleNameTh" id="input-2"
                                        placeholder="กรอกชื่อบทความภาษาไทย" required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="input-group-2" label="ชื่อบทความภาษาอังกฤษ:" label-for="input-2">
                                    <b-form-input v-model="articleNameEng" id="input-2"
                                        placeholder="กรอกชื่อบทความภาษาอังกฤษ" required></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row class="mb-32">
                            <b-col cols="6">
                                <b-form-group id="input-group-2" label="วัตถุประสงค์:" label-for="input-2">
                                    <textarea id="textarea" class="text-area-custom col-sm-12 col-md-12 col-xl-12 " v-model="objectText"
                                        placeholder="กรอกวัตถุประสงค์" rows="4" max-rows="6" cols="80" required>
                                </textarea>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group id="input-group-2" label="สถานะของบทความ:" label-for="input-2" required>
                                    <b-form-select v-model="articleStatusSelected"
                                        :options="articleStatus"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row class="mb-32">
                            <b-col cols="12">
                                <b-form-group id="input-group-2" label="" label-for="input-2">
                                    <div class="search-button">
                                        <b-button variant="outline-black-50" v-on:click="addAuthor()"
                                            :disabled="countAuthor === authorList.length" title="เพิ่มผู้เขียน">
                                            <i class="hp-text-color-dark-0 ri-2x ri-add-fill"></i>
                                        </b-button> &nbsp;

                                        <b-button variant="outline-black-50" v-on:click="removeAuthor()"
                                            :disabled="authors.length === 1" title="ลบผู้เขียน">
                                            <i class="hp-text-color-dark-0 ri-2x ri-subtract-fill"></i>
                                        </b-button>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-sm-12 col-md-6 col-xl-4" v-for="item, index in authors" :key="index">
                                <b-form-group id="input-group-2" :label="`ผู้เขียนคนที่ ${index + 1}`"
                                    label-for="input-2">
                                    <b-form-select v-model="authors[index].authorId" :options="authorList" required
                                        v-on:change="chooseAuthor(item, index)"></b-form-select>
                                </b-form-group>
                                <b-form-group id="input-group-2" label-for="input-2">
                                    <b-button variant="outline-black-50" :title="`อัพโหลดไฟล์ (doc, docx, pdf)`"
                                        v-on:click="uploadDocuments(index + 1)" v-if="authors[index].authorId != null">
                                        <i class="hp-text-color-dark-0 ri-2x ri-upload-2-fill"></i>
                                    </b-button> &nbsp;
                                    <b-button variant="outline-black-50" v-b-modal.modal-1
                                        v-on:click="chooseAuthorManageFile(authors[index].authorId)"
                                        v-if="downloadFile.filter(x => x.authorId === authors[index].authorId).length > 0">
                                        <i class="hp-text-color-dark-0 ri-2x ri-download-2-fill"></i>
                                    </b-button>

                                </b-form-group>
                                <input type="file" :id="`input-documents-${index + 1}`" multiple
                                    accept=".doc,.docx,.pdf,image/*" style="display: none;" ref="file"
                                    v-on:change="selectDocuments($event, authors[index].authorId)" />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <b-form-group id="input-group-2" label="บรรณาธิการประจำบท:" label-for="input-2">
                                    <b-form-select v-model="inspectorsSelected" :options="inspectors" required></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <b-form-group id="input-group-2" label="ผู้กลั่นกรองเบื้องต้น:" label-for="input-2" >
                                    <b-form-select v-model="initialModeratorSelected" 
                                        :options="initialModerator" required></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <b-form-group id="input-group-2" label="วันที่มอบหมาย:" label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`"
                                        v-model="assignmentDate" required></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="รับผลการกลั่นกรองเบื้องต้น:"
                                    label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`" required
                                        v-model="preliminaryFilteringResultDate"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="วันที่ส่งผลให้ผู้เขียน:" label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`" required
                                        v-model="sentResultToAuthorDate"></b-form-input>
                                </b-form-group>

                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="ผู้เขียนส่งการแก้ไขเบื้องต้น:" 
                                    label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`" v-model="editDate" required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="ชำระค่าธรรมเนียม:" label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`" v-model="payFeeDate" required></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">

                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-button v-on:click="uploadDocumentThatsentToAuthor()" variant="outline-black-50"
                                    :title="`อัพโหลดไฟล์ (doc, docx, pdf)`">
                                    <i class="hp-text-color-dark-0 ri-2x ri-upload-2-fill"></i>
                                </b-button> &nbsp;
                                <b-button variant="outline-black-50" v-b-modal.modal-2
                                    v-if="downloadSendResultToAuthorDate.filter(x => x.articleId === newArticleId).length > 0">
                                    <i class="hp-text-color-dark-0 ri-2x ri-download-2-fill"></i>
                                </b-button>
                                <input type="file" :id="`input-sent-to-author`" multiple
                                    accept=".doc,.docx,.pdf,image/*" style="display: none;"
                                    v-on:change="selectDocumentThatsentToAuthor($event)" />
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">

                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-button v-on:click="uploadDocumentFee()" variant="outline-black-50"
                                    :title="`อัพโหลดไฟล์ (doc, docx, pdf)`">
                                    <i class="hp-text-color-dark-0 ri-2x ri-upload-2-fill"></i>
                                </b-button> &nbsp;
                                <b-button variant="outline-black-50" v-b-modal.modal-3
                                    v-if="downloadPayFee.filter(x => x.articleId === newArticleId).length > 0">
                                    <i class="hp-text-color-dark-0 ri-2x ri-download-2-fill"></i>
                                </b-button>
                                <input type="file" :id="`input-document-fee`" multiple accept=".doc,.docx,.pdf,image/*"
                                    style="display: none;" v-on:change="selectDocumentFee($event)" />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row class="mb-32">
                            <b-col cols="12">
                                <b-form-group id="input-group-2" label="" label-for="input-2">
                                    <div class="search-button">
                                        <b-button variant="outline-black-50" v-on:click="addExpert()"
                                            title="เพิ่มผู้ทรงคุณวุฒิ">
                                            <i class="hp-text-color-dark-0 ri-2x ri-add-fill"></i>
                                        </b-button> &nbsp;

                                        <b-button variant="outline-black-50" v-on:click="removeExpert()"
                                            :disabled="this.experts.length === 1" title="ลบผู้ทรงคุณวุฒิ">
                                            <i class="hp-text-color-dark-0 ri-2x ri-subtract-fill"></i>
                                        </b-button>
                                    </div>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-sm-12 col-md-6 col-xl-4" v-for="expert, index in experts" :key="index">
                                <b-form-group id="input-group-2" :label="`ผู้ทรงคุณวุฒิคนที่ ${index + 1}`"
                                    label-for="input-2">
                                    <b-form-select v-model="experts[index].expertId" :options="expertList" 
                                         required></b-form-select>
                                </b-form-group>
                                <b-form-group id="input-group-2" :label="`ความเห็นผู้ทรงคุณวุฒิคนที่ ${index + 1}`"
                                    label-for="input-2">
                                    <b-form-select v-model="experts[index].commentId" :options="commentList"
                                        :disabled="!experts[index].expertId" required ></b-form-select>
                                </b-form-group>
                                <b-form-group id="input-group-2" label="ค่าตอบแทน:" label-for="input-2">
                                    <b-form-input id="input-2" placeholder="กรอกค่าตอบแทน" required
                                        v-model="experts[index].payFee"
                                        :disabled="!experts[index].expertId"></b-form-input>
                                </b-form-group>
                                <b-form-group id="input-group-2" label-for="input-2">
                                    <b-button variant="outline-black-50" :title="`อัพโหลดไฟล์ (doc, docx, pdf)`"
                                        v-on:click="uploadPayments(index + 1)" :disabled="!experts[index].expertId">
                                        <i class="hp-text-color-dark-0 ri-2x ri-upload-2-fill"></i>
                                    </b-button>
                                    &nbsp;
                                    <b-button variant="outline-black-50" v-b-modal.modal-4
                                        v-if="downloadPaymentFile.filter(x => x.expertId === experts[index].expertId).length > 0"
                                        v-on:click="choosePaymentManageFile(experts[index].expertId)">
                                        <i class="hp-text-color-dark-0 ri-2x ri-download-2-fill"></i>
                                    </b-button>
                                </b-form-group>
                                <input type="file" :id="`input-payments-${index + 1}`" multiple
                                    accept=".doc,.docx,.pdf,image/*" style="display: none;"
                                    v-on:change="selectPaymentsDocument($event, experts[index].expertId)" />
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="แจ้งผลรอบที่ 1:" label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`"
                                        v-model="announcentResult1" required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="ผู้เขียนการส่งการแก้ไขรอบที่ 1:"
                                    label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`"
                                        v-model="reportResultDate1" required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="แจ้งผลรอบที่ 2:" label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`"
                                        v-model="announcentResult2"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="ผู้เขียนการส่งการแก้ไขรอบที่ 2:"
                                    label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`"
                                        v-model="reportResultDate2"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label-for="input-2">
                                    <b-button variant="outline-black-50" :title="`อัพโหลดไฟล์ (doc, docx, pdf)`"
                                        v-on:click="uploadAnnouncentResult1()">
                                        <i class="hp-text-color-dark-0 ri-2x ri-upload-2-fill"></i>
                                    </b-button>
                                    &nbsp;
                                    <b-button variant="outline-black-50" v-b-modal.modal-5
                                        v-if="downloadAnnouncentResult1.filter(x => x.articleId === newArticleId).length > 0">
                                        <i class="hp-text-color-dark-0 ri-2x ri-download-2-fill"></i>
                                    </b-button>
                                </b-form-group>
                                <input type="file" :id="`input-announcent-result1`" multiple
                                    accept=".doc,.docx,.pdf,image/*" style="display: none;"
                                    v-on:change="selectAnnouncentResult1($event)" />
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">

                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label-for="input-2">
                                    <b-button variant="outline-black-50" :title="`อัพโหลดไฟล์ (doc, docx, pdf)`"
                                        v-on:click="uploadAnnouncentResult2()">
                                        <i class="hp-text-color-dark-0 ri-2x ri-upload-2-fill"></i>
                                    </b-button>
                                    &nbsp;
                                    <b-button variant="outline-black-50" v-b-modal.modal-6
                                        v-if="downloadAnnouncentResult2.filter(x => x.articleId === newArticleId).length > 0">
                                        <i class="hp-text-color-dark-0 ri-2x ri-download-2-fill"></i>
                                    </b-button>
                                </b-form-group>
                                <input type="file" :id="`input-announcent-result2`" multiple
                                    accept=".doc,.docx,.pdf,image/*" style="display: none;"
                                    v-on:change="selectAnnouncentResult2($event)" />
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">

                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="บรรณาธิการต้นฉบับ:" label-for="input-2">
                                    <b-form-select v-model="originalEditorSelected"
                                        :options="originalEditor" required></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="วันที่มอบหมายบรรณาธิการต้นฉบับ:"
                                    label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`"
                                        v-model="assignmentOriginalEditorDate" required></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="บรรณาธิการฝ่ายผลิต:" label-for="input-2">
                                    <b-form-select v-model="productionEditorSelected"
                                        :options="productionEditor" required></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="วันที่มอบหมายบรรณาธิการฝ่ายผลิต:"
                                    label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`"
                                        v-model="assignmentProductionDate" required></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" class="mt-16">
                        <b-row>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="ฉบับที่เผยแพร่:" label-for="input-2">
                                    <b-form-select v-model="journalSelected" :options="journals" required></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <b-form-group id="input-group-2" label="วันที่เผยแพร่:" label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`" v-model="publishDate" required></b-form-input>
                                </b-form-group>
                            </b-col>

                        </b-row>
                    </b-col>
                    <div class="submit-container">
                        <b-button type="submit" variant="outline-black-100" >บันทึก</b-button>
                    </div>
                </b-form>
            </b-card>
        </div>
        <!-- Author Modal -->

        <b-modal id="modal-1" hide-footer header-class="align-items-center">
            <template #modal-header="{ close }">
                <h5 class="mb-0">ไฟล์เอกสารของ {{ authorForManageFile.name }} </h5>

                <b-button variant="text" @click="close()"
                    class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
                    style="min-height: 24px;">
                    <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
                </b-button>
            </template>

            <p class="hp-p1-body mb-0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>ดาวน์โหลดไฟล์เอกสาร</th>
                        <th> #</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in downloadFile.filter(x => x.authorId == authorForManageFile?.id)"
                        :key="item.id">
                        <td><a class="btn btn-outline-dark" :href="item.download">ดาวน์โหลด {{ index + 1 }}</a></td>
                        <td><button type="button" class="btn btn-outline-danger"
                                v-on:click="deleteDocumentFileAuthor(item.fullPath, item.authorId)">ลบ</button></td>
                    </tr>

                </tbody>
            </table>
            </p>
            <div class="d-flex justify-content-end flex-wrap mt-32">
                <b-button variant="text" v-on:click="$bvModal.hide('modal-1')">
                    ปิด
                </b-button>
            </div>
        </b-modal>

        <!-- Send to author date Modal -->
        <b-modal id="modal-2" hide-footer header-class="align-items-center">
            <template #modal-header="{ close }">
                <h5 class="mb-0">ไฟล์เอกสารที่ส่งให้ผู้เขียน</h5>

                <b-button variant="text" @click="close()"
                    class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
                    style="min-height: 24px;">
                    <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
                </b-button>
            </template>

            <p class="hp-p1-body mb-0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>ดาวน์โหลดไฟล์เอกสาร</th>
                        <th> #</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in downloadSendResultToAuthorDate.filter(x => x.articleId == newArticleId)"
                        :key="item.id">
                        <td><a class="btn btn-outline-dark" :href="item.download">ดาวน์โหลด {{ index + 1 }}</a></td>
                        <td><button type="button" class="btn btn-outline-danger"
                                v-on:click="deleteDocumentFileSendResultToAuthor(item.fullPath, newArticleId)">ลบ</button>
                        </td>
                    </tr>

                </tbody>
            </table>
            </p>
            <div class="d-flex justify-content-end flex-wrap mt-32">
                <b-button variant="text" v-on:click="$bvModal.hide('modal-2')">
                    ปิด
                </b-button>
            </div>
        </b-modal>

        <!-- Pay Fee Modal -->
        <b-modal id="modal-3" hide-footer header-class="align-items-center">
            <template #modal-header="{ close }">
                <h5 class="mb-0">ไฟล์เอกสารชำระค่าธรรมเนียม</h5>

                <b-button variant="text" @click="close()"
                    class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
                    style="min-height: 24px;">
                    <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
                </b-button>
            </template>

            <p class="hp-p1-body mb-0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>ดาวน์โหลดไฟล์เอกสาร</th>
                        <th> #</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in downloadPayFee.filter(x => x.articleId == newArticleId)" :key="item.id">
                        <td><a class="btn btn-outline-dark" :href="item.download">ดาวน์โหลด {{ index + 1 }}</a></td>
                        <td><button type="button" class="btn btn-outline-danger"
                                v-on:click="deleteDocumentFilePayFee(item.fullPath, newArticleId)">ลบ</button></td>
                    </tr>

                </tbody>
            </table>
            </p>
            <div class="d-flex justify-content-end flex-wrap mt-32">
                <b-button variant="text" v-on:click="$bvModal.hide('modal-3')">
                    ปิด
                </b-button>
            </div>
        </b-modal>

        <!-- Expert Payment Modal -->
        <b-modal id="modal-4" hide-footer header-class="align-items-center">
            <template #modal-header="{ close }">
                <h5 class="mb-0">ไฟล์เอกสารของผู้ทรงคุณวุฒิ {{ paymentForManageFile.name }} </h5>

                <b-button variant="text" @click="close()"
                    class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
                    style="min-height: 24px;">
                    <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
                </b-button>
            </template>

            <p class="hp-p1-body mb-0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>ดาวน์โหลดไฟล์เอกสาร</th>
                        <th> #</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in downloadPaymentFile.filter(x => x.expertId == paymentForManageFile?.id)"
                        :key="item.id">
                        <td><a class="btn btn-outline-dark" :href="item.download">ดาวน์โหลด {{ index + 1 }}</a></td>
                        <td><button type="button" class="btn btn-outline-danger"
                                v-on:click="deleteDocumentFilePayment(item.fullPath, item.expertId)">ลบ</button></td>
                    </tr>

                </tbody>
            </table>
            </p>
            <div class="d-flex justify-content-end flex-wrap mt-32">
                <b-button variant="text" v-on:click="$bvModal.hide('modal-4')">
                    ปิด
                </b-button>
            </div>
        </b-modal>

         <!-- AnnouncentResult1 Modal -->
         <b-modal id="modal-5" hide-footer header-class="align-items-center">
            <template #modal-header="{ close }">
                <h5 class="mb-0">ไฟล์เอกสารแจ้งผลรอบที่ 1</h5>
                <b-button variant="text" @click="close()"
                    class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
                    style="min-height: 24px;">
                    <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
                </b-button>
            </template>
            <p class="hp-p1-body mb-0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>ดาวน์โหลดไฟล์เอกสาร</th>
                        <th> #</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in downloadAnnouncentResult1.filter(x => x.articleId == newArticleId)"
                        :key="item.id">
                        <td><a class="btn btn-outline-dark" :href="item.download">ดาวน์โหลด {{ index + 1 }}</a></td>
                        <td><button type="button" class="btn btn-outline-danger"
                                v-on:click="deleteDocumentAnnouncentResult1(item.fullPath, newArticleId)">ลบ</button></td>
                    </tr>

                </tbody>
            </table>
            </p>
            <div class="d-flex justify-content-end flex-wrap mt-32">
                <b-button variant="text" v-on:click="$bvModal.hide('modal-5')">
                    ปิด
                </b-button>
            </div>
        </b-modal>
        <!-- AnnouncentResult2 Modal -->
        <b-modal id="modal-6" hide-footer header-class="align-items-center">
            <template #modal-header="{ close }">
                <h5 class="mb-0">ไฟล์เอกสารแจ้งผลรอบที่ 2</h5>
                <b-button variant="text" @click="close()"
                    class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
                    style="min-height: 24px;">
                    <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px"></i>
                </b-button>
            </template>
            <p class="hp-p1-body mb-0">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th>ดาวน์โหลดไฟล์เอกสาร</th>
                        <th> #</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in downloadAnnouncentResult2.filter(x => x.articleId == newArticleId)"
                        :key="item.id">
                        <td><a class="btn btn-outline-dark" :href="item.download">ดาวน์โหลด {{ index + 1 }}</a></td>
                        <td><button type="button" class="btn btn-outline-danger"
                                v-on:click="deleteDocumentAnnouncentResult2(item.fullPath, newArticleId)">ลบ</button></td>
                    </tr>

                </tbody>
            </table>
            </p>
            <div class="d-flex justify-content-end flex-wrap mt-32">
                <b-button variant="text" v-on:click="$bvModal.hide('modal-6')">
                    ปิด
                </b-button>
            </div>
        </b-modal>
    </b-row>


</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BAlert,
    BSpinner,
    BModal,
    BTable,
    BBreadcrumb,
} from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import commentationService from "../../../../../services/commentation.service";
import moment from "moment-timezone";
import authorService from "../../../../../services/author.service";
import articleTypeService from "../../../../../services/article-type.service";
import publishTypeService from "../../../../../services/publish-type.service";
import inspectorService from "../../../../../services/inspector.service";
import expertService from "../../../../../services/expert.service";
import journalService from "../../../../../services/journal.service";
import { generateUUIDV4 } from "../../../../../helpers/validation";
import uploadService from "../../../../../services/upload.service";
import formService from "../../../../../services/form.service";
import articleStatusService from "../../../../../services/article-status.service";
import LoaderComponent from "../../../../components/loader/index.vue"

const downloadUrl = process.env.VUE_APP_DOWNLOADURL

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        BTable,
        PageTitle,
        LoaderComponent
    },
    data() {
        return {
            breadcrumbItems: [{
                text: "ตารางบทความ",
                href: "#",
            },
            {
                text: "ตาราง",
                to: {
                    name: "article-form"
                },
            },
            {
                text: "เพิ่มบทความ",
                active: true,
            },
            ],
            comment: null,
            show: true,
            role: null,
            isLoading: false,
            errors: [],
            timeZone: "Asia/Bangkok",
            isDisabledButton: true,
            dateNow: new Date(),
            authors: [],
            authorList: [],
            authorAction: {},
            authorListSelected: [],
            expertProperty: { expertId: null, commentId: null, payFree: null },
            payForFree: null,
            articleType: [],
            articleTypeSelected: null,
            publishType: [],
            publishTypeSelected: null,
            initialModerator: [],
            initialModeratorSelected: null,
            inspectors: [],
            inspectorsSelected: null,
            experts: [],
            expertList: [],
            commentList: [],
            comments: [],
            reportResult1: null,
            reportResult2: null,
            articleCode: null,
            articleNameTh: null,
            articleNameEng: null,
            originalEditor: [],
            originalEditorSelected: null,
            productionEditor: [],
            productionEditorSelected: null,
            journals: [],
            journalSelected: null,
            receivedDate: null,
            assignmentDate: null,
            editDate: null,
            payFeeDate: null,
            reportResultDate1: null,
            reportResultDate2: null,
            preliminaryFilteringResultDate: null,
            sentResultToAuthorDate: null,
            assignmentOriginalEditorDate: null,
            assignmentProductionDate: null,
            publishDate: null,
            announcentResult1: null,
            announcentResult2: null,
            objectText: null,
            articleStatus: [],
            articleStatusSelected: null,
            based64articleDocuments: [],
            based64SentAuthorDocuments: [],
            countAuthor: 0,
            newArticleId: generateUUIDV4(),
            newInitialAssessmentId: generateUUIDV4(),
            based64PayFeeDocuments: [],
            based64PaymentDocuments: [],
            based64Announcent1Documents: [],
            based64Announcent2Documents: [],
            file: '',
            downloadFile: [],
            authorForManageFile: null,
            downloadSendResultToAuthorDate: [],
            downloadPayFee: [],
            paymentForManageFile: null,
            downloadPaymentFile: [],
            downloadAnnouncentResult1 : [],
            downloadAnnouncentResult2 : []

        };
    },
    computed: {

    },
    methods: {
        getCurrentDate() {
            let currentDate = moment(this.dateNow).tz(this.timeZone).add(543, 'y').format('yyyy-MM-DD');
            this.receivedDate = currentDate;
            this.assignmentDate = currentDate;
            this.editDate = currentDate;
            this.payFeeDate = currentDate;
            this.reportResultDate1 = currentDate;
            this.reportResultDate2 = null;
            this.preliminaryFilteringResultDate = currentDate;
            this.sentResultToAuthorDate = currentDate;
            this.assignmentOriginalEditorDate = currentDate;
            this.assignmentProductionDate = currentDate;
            this.publishDate = currentDate;
            this.announcentResult1 = currentDate;
            this.announcentResult2 = null;
        },
        uploadDocuments(index) {
            document.getElementById(`input-documents-${index}`).click();
        },
        selectDocuments: async function (event, authorId) {
            let newArticleId = this.newArticleId;
            let fileLength = event.target.files.length;
            let files = event.target.files;
            this.isLoading = true;
            for (let i = 0; i <= fileLength; i++) {
                const formData = new FormData();
                if (files[i]) {
                    formData.append('document_files', event.target.files[i]);
                    let result = await uploadService.uploadfile(formData);
                    let uploadfile = result.data.uploads;
                    this.based64articleDocuments.push({ base64file: uploadfile, articleId: newArticleId, authorId: authorId });
                    this.downloadFile.push({ download: downloadUrl + uploadfile, fullPath: uploadfile, authorId: authorId });
                }
            }
            this.isLoading = false;
        },
        chooseAuthorManageFile(authorId) {
            let author = this.authorList.find(x => x.value === authorId);
            this.authorForManageFile = { id: author.value, name: author.text };

        },
        deleteDocumentFileAuthor: async function (fileName, authorId) {
            let newR = { file_name: fileName }
            let removeIndex = this.downloadFile.findIndex(x => x.fullPath === fileName && x.authorId === authorId)
            if (removeIndex > -1) {
                this.downloadFile.splice(removeIndex, 1);
                this.based64articleDocuments.splice(removeIndex, 1);
                await uploadService.deletefile(newR);
            }
        },

        uploadPayments(index) {
            document.getElementById(`input-payments-${index}`).click();
        },

        choosePaymentManageFile(expertId) {
            let expert = this.expertList.find(x => x.value === expertId);
            console.log("expert", expert);
            this.paymentForManageFile = { id: expert.value, name: expert.text };

        },
        selectPaymentsDocument: async function (event, expertId) {
            let fileLength = event.target.files.length;
            let files = event.target.files;
            let newArticleId = this.newArticleId;
            this.isLoading = true;
            for (let i = 0; i <= fileLength; i++) {
                const formData = new FormData();
                if (files[i]) {
                    formData.append('document_files', event.target.files[i]);
                    let result = await uploadService.uploadfile(formData);
                    let uploadfile = result.data.uploads;
                    this.based64PaymentDocuments.push({ base64file: uploadfile, articleId: newArticleId,  expertId: expertId  });
                    this.downloadPaymentFile.push({ download: downloadUrl + uploadfile, fullPath: uploadfile, expertId: expertId });
                }
            }
            this.isLoading = false;
        },

        deleteDocumentFilePayment: async function (fileName, expertId) {
            let newR = { file_name: fileName }
            let removeIndex = this.downloadPaymentFile.findIndex(x => x.fullPath === fileName && x.expertId === expertId)
            if (removeIndex > -1) {
                this.downloadPaymentFile.splice(removeIndex, 1);
                this.based64PaymentDocuments.splice(removeIndex, 1);
                await uploadService.deletefile(newR);
            }
        },


        uploadDocumentThatsentToAuthor() {
            document.getElementById(`input-sent-to-author`).click();
        },
        selectDocumentThatsentToAuthor: async function (event, id) {
            let fileLength = event.target.files.length;
            let files = event.target.files;
            let newArticleId = this.newArticleId;
            this.isLoading = true;
            for (let i = 0; i <= fileLength; i++) {
                const formData = new FormData();
                if (files[i]) {
                    formData.append('document_files', event.target.files[i]);
                    let result = await uploadService.uploadfile(formData);
                    let uploadfile = result.data.uploads;
                    this.based64SentAuthorDocuments.push({ base64file: uploadfile, articleId: newArticleId });
                    this.downloadSendResultToAuthorDate.push({ download: downloadUrl + uploadfile, fullPath: uploadfile, articleId: newArticleId });
                }
            }
            this.isLoading = false;
        },

        deleteDocumentFileSendResultToAuthor: async function (fileName, articleId) {
            let newR = { file_name: fileName }
            let removeIndex = this.downloadSendResultToAuthorDate.findIndex(x => x.fullPath === fileName && x.articleId === articleId)
            if (removeIndex > -1) {
                this.downloadSendResultToAuthorDate.splice(removeIndex, 1);
                this.based64SentAuthorDocuments.splice(removeIndex, 1);
                await uploadService.deletefile(newR);
            }
        },

        uploadDocumentFee() {
            document.getElementById(`input-document-fee`).click();
        },
        selectDocumentFee: async function (event, id) {
            let fileLength = event.target.files.length;
            let files = event.target.files;
            let newArticleId = this.newArticleId;
            this.isLoading = true;
            for (let i = 0; i <= fileLength; i++) {
                const formData = new FormData();
                if (files[i]) {
                    formData.append('document_files', event.target.files[i]);
                    let result = await uploadService.uploadfile(formData);
                    let uploadfile = result.data.uploads;
                    this.based64PayFeeDocuments.push({ base64file: uploadfile, articleId: newArticleId });
                    this.downloadPayFee.push({ download: downloadUrl + uploadfile, fullPath: uploadfile, articleId: newArticleId });
                }
            }
            this.isLoading = false;
        },

        deleteDocumentFilePayFee: async function (fileName, articleId) {
            let newR = { file_name: fileName }
            let removeIndex = this.downloadPayFee.findIndex(x => x.fullPath === fileName && x.articleId === articleId)
            if (removeIndex > -1) {
                this.downloadPayFee.splice(removeIndex, 1);
                this.based64PayFeeDocuments.splice(removeIndex, 1);
                await uploadService.deletefile(newR);
            }
        },

        uploadAnnouncentResult1() {
            document.getElementById(`input-announcent-result1`).click();
        },
        selectAnnouncentResult1: async function (event) {
            let fileLength = event.target.files.length;
            let files = event.target.files;
            let newArticleId = this.newArticleId;
            this.isLoading = true;
            for (let i = 0; i <= fileLength; i++) {
                const formData = new FormData();
                if (files[i]) {
                    formData.append('document_files', event.target.files[i]);
                    let result = await uploadService.uploadfile(formData);
                    let uploadfile = result.data.uploads;
                    this.based64Announcent1Documents.push({ base64file: uploadfile, filename: "announcent1", articleId: newArticleId });
                    this.downloadAnnouncentResult1.push({ download: downloadUrl + uploadfile, fullPath: uploadfile, articleId: newArticleId });
                }
            }
            this.isLoading = false;
        },

        deleteDocumentAnnouncentResult1: async function (fileName, articleId) {
            let newR = { file_name: fileName }
            let removeIndex = this.downloadAnnouncentResult1.findIndex(x => x.fullPath === fileName && x.articleId === articleId)
            if (removeIndex > -1) {
                this.downloadAnnouncentResult1.splice(removeIndex, 1);
                this.based64Announcent1Documents.splice(removeIndex, 1);
                await uploadService.deletefile(newR);
            }
        },

        uploadAnnouncentResult2() {
            document.getElementById(`input-announcent-result2`).click();
        },
        selectAnnouncentResult2: async function (event) {
            let fileLength = event.target.files.length;
            let files = event.target.files;

            let result = [];
            let newArticleId = this.newArticleId;
            this.isLoading = true;
            for (let i = 0; i <= fileLength; i++) {
                const formData = new FormData();
                if (files[i]) {
                    formData.append('document_files', event.target.files[i]);
                    let result = await uploadService.uploadfile(formData);
                    let uploadfile = result.data.uploads;
                    this.based64Announcent2Documents.push({ base64file: uploadfile, filename: "announcent2", articleId: newArticleId });
                    this.downloadAnnouncentResult2.push({ download: downloadUrl + uploadfile, fullPath: uploadfile, articleId: newArticleId });
                    
                }
            }
            this.isLoading = false;
        },

        deleteDocumentAnnouncentResult2: async function (fileName, articleId) {
            let newR = { file_name: fileName }
            let removeIndex = this.downloadAnnouncentResult1.findIndex(x => x.fullPath === fileName && x.articleId === articleId)
            if (removeIndex > -1) {
                this.downloadAnnouncentResult2.splice(removeIndex, 1);
                this.based64Announcent2Documents.splice(removeIndex, 1);
                await uploadService.deletefile(newR);
            }
        },

        addAuthor() {
            this.countAuthor = 0;
            this.authors.push({
                index: this.countAuthor,
                authorId: null,
                selectedAuthor: null
            });
            this.countAuthor++;
        },
        removeAuthor() {
            this.authors.pop();
        },
        chooseAuthor(item, index) {

            if (this.authorListSelected.filter((item, iindex) => { return iindex == index }).length > 0) {
                let authorList = this.authorListSelected.map((authorId, authorIndex) => {
                    if (authorIndex == index) {
                        authorId = authorId
                        return authorId;
                    }
                    return authorId;
                })
                this.authorListSelected = authorList;
            } else {
                this.authorListSelected.push(item.authorId)
            }
        },
        addExpert() {
            let num = 0;
            this.experts.push({
                index: num,
                expertId: null,
                commentId: null,
                payFee: null
            });
            this.addComment(num);
            num++
        },
        removeExpert() {
            this.experts.pop();
        },

        addComment(index) {
            this.comments.push({
                index: index,
                commentId: null,
                selectedComment: null
            });
        },

        getAuthors: async function () {
            let response = await authorService.getAuthor();
            let data = response.data.authors;
            this.authorList = [{ value: null, text: "กรุณาเลือกผู้เขียน" }];
            data.forEach((item) => {
                let newR = {
                    value: item.id,
                    text: item.firstname_th + " " + item.lastname_th
                }
                this.authorList.push(newR);
            });
        },
        getArticleType: async function () {
            let response = await articleTypeService.getArticleType();
            let data = response.data.article_types;
            this.articleType = [{ value: null, text: "กรุณาประเภทบทความ" }];
            data.forEach((item) => {
                let newR = {
                    value: item.id,
                    text: item.type_name
                }
                this.articleType.push(newR);
            });
        },
        getPublishType: async function () {
            let response = await publishTypeService.getPublishType();
            let data = response.data.publish_type;
            this.publishType = [{ value: null, text: "กรุณาประเภทเผยแพร่" }];
            data.forEach((item) => {
                let newR = {
                    value: item.id,
                    text: item.publish_name
                }
                this.publishType.push(newR);
            })
        },
        getInspectors: async function () {
            let response = await inspectorService.getInspector();
            let data = response.data.inspectors;
            this.inspectors = [{ value: null, text: "กรุณาเลือกบรรณาธิการประจำบท" }];
            this.initialModerator = [{ value: null, text: "กรุณาเลือกผู้กลั่นกรองเบื้องต้น" }];
            this.originalEditor = [{ value: null, text: "กรุณาเลือกบรรณาธิการต้นฉบับ" }];
            this.productionEditor = [{ value: null, text: "กรุณาเลือกบรรณาธิการฝ่ายผลิต" }];

            data.forEach((item) => {
                let newR = {
                    value: item.id,
                    text: item.firstname + " " + item.lastname
                }
                this.inspectors.push(newR);

            });
            this.initialModerator = this.inspectors;
            this.originalEditor = this.inspectors;
            this.productionEditor = this.inspectors;
        },
        getExperts: async function () {
            let response = await expertService.getExpert();
            let data = response.data.experts;
            this.expertList = [{ value: null, text: "กรุณาเลือกผู้ทรงคุณวุฒิ" }];
            data.forEach((item) => {
                let newR = {
                    value: item.id,
                    text: item.firstname_th + " " + item.lastname_th
                }
                this.expertList.push(newR);
            });
        },

        getComments: async function () {
            let response = await commentationService.getComment();
            let data = response.data.commentation;
            this.commentList = [{ value: null, text: "กรุณาเลือกความเห็น" }];
            data.forEach((item) => {
                let newR = {
                    value: item.id,
                    text: item.comment
                }
                this.commentList.push(newR);
            });
        },
        getJournals: async function () {
            let response = await journalService.getJournal();
            let data = response.data.journals;
            this.journals = [{ value: null, text: "กรุณาเลือกเล่มวารสาร" }];
            data.forEach((item) => {
                let newR = {
                    value: item.id,
                    text: "ปีที่ " + item.year + " ฉบับที่ " + item.issue + " หน้า " + item.page_number
                }
                this.journals.push(newR);
            });
        },
        getArticleStatus: async function () {
            let response = await articleStatusService.getArticleStatus();
            let data = response.data.article_status;
            this.articleStatus = [{ value: null, text: "กรุณาเลือกสถานะบทความ" }];
            data.forEach((item) => {
                let newR = {
                    value: item.id,
                    text: item.status_name
                }
                this.articleStatus.push(newR);
            });
        },
        saveNewArticle: async function (event) {
            event.preventDefault()
            this.isLoading = true;
            let minutes = "00:00:00";


            if (!this.isValidate()) {

                let based64articleDocuments = [];
                this.based64articleDocuments.map((item) => {
                    let document = {
                        article_id: this.newArticleId,
                        article_code: this.articleCode,
                        filename: "article",
                        filepath: item.base64file,
                        author_id: item.authorId
                    }
                    based64articleDocuments.push(document);
                });

                let based64SentAuthorDocuments = [];
                this.based64SentAuthorDocuments.map((item) => {
                    let initialAssessment = {
                        initial_assessment_id: this.newInitialAssessmentId,
                        filename: "sentAuthorDate",
                        filepath: item.base64file,
                    }
                    based64SentAuthorDocuments.push(initialAssessment)
                });
                let based64PayFeeDocuments = [];
                this.based64PayFeeDocuments.map((item) => {
                    let payFee = {
                        initial_assessment_id: this.newInitialAssessmentId,
                        filename: "payFee",
                        filepath: item.base64file,
                    }
                    based64PayFeeDocuments.push(payFee)
                });
                let based64PaymentDocuments = [];
                this.based64PaymentDocuments.map((item) => {
                    let payments = {
                        initial_assessment_id: this.newInitialAssessmentId,
                        filename: "payment",
                        filepath: item.base64file,
                        expert_id: item.expertId
                    }
                    based64PaymentDocuments.push(payments);
                });
                let based64Announcent1Documents = [];
                this.based64Announcent1Documents.map((item) => {
                    let payments = {
                        initial_assessment_id: this.newInitialAssessmentId,
                        filename: item.filename,
                        filepath: item.base64file,
                        expert_id: item.expertId
                    }
                    based64Announcent1Documents.push(payments);
                });

                let based64Announcent2Documents = [];
                this.based64Announcent2Documents.map((item) => {
                    let payments = {
                        initial_assessment_id: this.newInitialAssessmentId,
                        filename: item.filename,
                        filepath: item.base64file,
                        expert_id: item.expertId
                    }
                    based64Announcent2Documents.push(payments);
                });

                let expertListSelected = []
                this.experts.map((expert) => {
                    let articleIntegrateExpert = {
                        article_id: this.newArticleId,
                        expert_id: expert.expertId,
                        comment_id : expert.commentId,
                        pay_fee : expert.payFee,
                    }
                    expertListSelected.push(articleIntegrateExpert)
                })
                let authorListSelected = [];
                this.authorListSelected.map((authorId) => {
                    let articleIntegrateAuthor = {
                        article_id: this.newArticleId,
                        author_id: authorId
                    };
                    authorListSelected.push(articleIntegrateAuthor);
                })
               
                let formAdd = {
                    article: {
                        id: this.newArticleId,
                        received_date: this.receivedDate + " " + minutes,
                        article_type_id : this.articleTypeSelected,
                        publish_type_id : this.publishTypeSelected,
                        name_th: this.articleNameTh,
                        name_eng: this.articleNameEng,
                        objective: this.objectText,
                        status_id: this.articleStatusSelected,
                        article_code: this.articleCode,
                        original_editor: this.originalEditorSelected,
                        assignment_original_editor_date: this.assignmentOriginalEditorDate  + " " + minutes,
                        production_editor: this.productionEditorSelected,
                        assignment_production_date: this.assignmentProductionDate  + " " + minutes,
                        journal_id: this.journalSelected,
                        publish_date: this.publishDate  + " " + minutes
                    },
                    article_file: based64articleDocuments,
                    sent_author_doc: based64SentAuthorDocuments,
                    pay_fee_doc: based64PayFeeDocuments,
                    payment: based64PaymentDocuments,
                    announcent1_doc: based64Announcent1Documents,
                    announcent2_doc: based64Announcent2Documents,
                    initailAssessment: {
                        id: this.newInitialAssessmentId,
                        article_id: this.newArticleId,
                        inspector_id: this.inspectorsSelected,
                        initial_moderator_id: this.initialModeratorSelected,
                        assessment_date: this.sentResultToAuthorDate + " " + minutes,
                        preliminary_filtering_result_date: this.preliminaryFilteringResultDate + " " + minutes,
                        edit_date: this.editDate + " " + minutes,
                        pay_fee_date: this.payFeeDate + " " + minutes,
                        announcent1: this.announcentResult1 + " " + minutes,
                        report_edit1: this.reportResultDate1 + " " + minutes,
                        announcent2: this.announcentResult2 ? this.announcentResult2 + " " + minutes : null,
                        report_edit2: this.reportResultDate2 ? moment(this.reportResultDate2).tz(this.timeZone).add(543, 'y').format('yyyy-MM-DD') : null
                    },
                    experts: expertListSelected,
                    authors: authorListSelected
                }
                console.log("add form", formAdd);
                await formService.addform(formAdd);

               history.back();
            } else {
                this.isLoading = false;
                this.$bvModal
                    .msgBoxConfirm("กรุณาข้อมูลให้ครบถ้วน", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }

        },
        isValidate() {
            this.errors = [];
            if (!this.articleCode) this.errors.push("Article code required.");
            if (!this.articleTypeSelected) this.errors.push("Article Type required.");
            if (!this.publishTypeSelected) this.errors.push("Publish type required.");
            if (!this.articleNameTh) this.errors.push("Article Name Th required.");
            if (!this.articleNameEng) this.errors.push("Article Name Eng required.");
            if (!this.inspectorsSelected) this.errors.push("Inspector required.");
            if (!this.initialModeratorSelected) this.errors.push("Initial Moderator required.");
            if (!this.reportResult1) this.errors.push("Report Result 1 required.");
            if (!this.journalSelected) this.errors.push("Report Result 2 required.");
            if (!this.originalEditor) this.errors.push("Original Editor required.");
            if (!this.productionEditor) this.errors.push("Production Editor required.");
            if (!this.objectText) this.errors.push("Object required.");
            if (!this.articleStatusSelected) this.errors.push("Article Status required.");

            if (!this.errors.length) {
                return true;
            }
            return false;
        },

    },
    mounted() {
        this.getCurrentDate();
        this.addAuthor();
        this.getAuthors();
        this.getArticleType();
        this.getPublishType();
        this.getInspectors();
        this.getExperts();
        this.addExpert();
        this.getComments();
        this.getJournals();
        this.getArticleStatus();
        console.log(process.env.VUE_APP_APIURL)
    }

};
</script>
